import { require } from './../../utils/http'
//查询经纪人列表
function search_agent(obj) {
  return require('post', '/api/kapin_elite/agent/v1/search_agent/', obj)
}
//设置公司展示
function get_agent(obj) {


  return require('post', '/api/kapin_elite/agent/v1/get_agent/', obj)
}



//设置公司展示
function audit_agent(obj) {


  return require('post', '/api/kapin_elite/agent/v1/audit_agent/', obj)
}

// 查询所有职位包含悬赏和非悬赏
function jd_search(obj) {

  return require('post', '/api/kapin_elite/agent/v1/jd_search/', obj)
}
// 添加悬赏职位
function jd_agent_add(obj) {

  return require('post', '/api/kapin_elite/agent/v1/jd_agent_add/', obj)
}
// 查询已经参与悬赏的职位
function jd_agent_list(obj) {

  return require('post', '/api/kapin_elite/agent/v1/jd_agent_list/', obj)
}

// 查询投递简历记录
function pc_elite_money(obj) {

  return require('post', '/api/kapin_elite/agent/v1/pc_elite_money/', obj)
}


// 更新投递简历状态信息 如发放佣金，入职信息等
function update_money(obj) {

  return require('post', '/api/kapin_elite/agent/v1/update_money/', obj)
}

// 更新权限等
function update_quanxian(obj) {

  return require('post', '/api/kapin_elite/agent/v1/update_quanxian/', obj)
}




//查询经纪人列表_公司调用
function agent_company(obj) {
  return require('post', '/api/kapin_elite/agent/v1/agent_company/', obj)
}
//根据手机号查询经纪人查询——公司调用根据
function agent_company_by_phone(obj) {
  return require('post', '/api/kapin_elite/agent/v1/agent_company_by_phone/', obj)
}
// 申请通过_公司调用 audit_agent
function audit_company(obj) {

  return require('post', '/api/kapin_elite/agent/v1/audit_company/', obj)
}

// 查询本公司投递简历记录
function get_elite_money_all(obj) {

  return require('post', '/api/kapin_elite/agent/v1/get_elite_money_all/', obj)
}
// 查询本公司投递简历记录
function agent_company_code(obj) {

  return require('post', '/api/kapin_elite/agent/v1/agent_company_code/', obj)
}





export { search_agent, get_agent, audit_agent, jd_search, jd_agent_add, jd_agent_list, pc_elite_money, update_money,
   update_quanxian ,agent_company,audit_company,agent_company_by_phone,get_elite_money_all,agent_company_code}

